import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../layout';
import Colors from '../components/Colors';
import Typography from '../components/Typography';
import Iframe from '../components/Iframe';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "style-guide"
    }}>{`Style Guide`}</h1>
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <Colors mdxType="Colors" />
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <Typography mdxType="Typography" />
    <p>{`Reset theme customizations:
`}<inlineCode parentName="p">{`<ResetTheme />`}</inlineCode></p>
    <h2 {...{
      "id": "logos"
    }}>{`Logos`}</h2>
    <Iframe title='figma' src='https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/file/QaYbQ2LGdA3lefOWZCosDcrt/DSC-NYC-logos' mdxType="Iframe" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      